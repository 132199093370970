import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2eb547f9 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _f8303fac = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _d3e1e958 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _11bd6f6f = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _0efc58bc = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _0f54c42c = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0ea5ec58 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _3e7eea43 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _f99f8352 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _60f2a0fa = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _55838da5 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _78940b3f = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _40e3a01a = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _044e271c = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _4eee151d = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _dd102dfc = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _2eadfad3 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _2eb547f9,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _f8303fac,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _d3e1e958,
    name: "callback"
  }, {
    path: "/downloads",
    component: _11bd6f6f,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _0efc58bc,
    name: "guests-details"
  }, {
    path: "/login",
    component: _0f54c42c,
    name: "login"
  }, {
    path: "/maintenance",
    component: _0ea5ec58,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _3e7eea43,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _f99f8352,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _60f2a0fa,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _55838da5,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _78940b3f,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _40e3a01a,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _044e271c,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _4eee151d,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _dd102dfc,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _2eadfad3,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
